<template>
  <div>
    <!-- 验货处理 -->
    <el-tabs
      v-model="activeName"
      type="card"
      :before-leave="Pc"
    >
      <el-tab-pane
        v-if="pageStatus != 2"
        :label="tltle"
        name="first"
      >
        <div class="newForm">
          <div class="M_H">
            <el-button
              class="seniorbut"
              @click="onSubmit"
            >保 存</el-button>
          </div>
          <el-form
            ref="newForm"
            :model="newForm"
            :rules="newFormRules"
            label-width="100px"
          >
            <div class="M">
              <div
                class="inlineDiv"
                v-if="isOther"
              >
                <div class="queryItem1">
                  <el-form-item label="快递单号:">
                    <el-select
                      v-model="expressNum"
                      filterable
                      remote
                      reserve-keyword
                      value-key="courierNumber"
                      placeholder="请输入关键词"
                      :remote-method="remoteMethod"
                      @change="SE"
                      :loading="loading"
                    >
                      <el-option
                        v-for="item in expressNumArr"
                        :key="item.courierNumber"
                        :label="item.courierNumber"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <el-button
                  class="seniorbut alonebut"
                  @click="QE"
                >查 询</el-button>
              </div>
              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item
                    label="快递单号:"
                    prop="courierNumber"
                  >
                    <el-input
                      v-model="newForm.courierNumber"
                      readonly
                      placeholder="自动填充"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item
                    label="集运仓库:"
                    prop="storageId"
                  >
                    <el-input
                      v-model="newForm.storageName"
                      readonly
                      placeholder="自动填充"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item
                    label="预报备注:"
                    prop="packageComment"
                  >
                    <el-input
                      v-model="newForm.packageComment"
                      readonly
                      placeholder="自动填充"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="queryItem1">
                <el-form-item
                  label="申请备注:"
                  prop="comment"
                >
                  <el-input
                    v-model="newForm.comment"
                    placeholder="请输入申请备注"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div
              class="M"
              style="margin-top: 40px"
            >
              <el-form-item
                label=" "
                label-width="1px"
                style="height: 60px"
              >
                <el-checkbox-group v-model="expenditureList">
                  <el-checkbox
                    v-for="item in serviceArr"
                    :label="item.itemServiceId"
                    :key="item.itemServiceId"
                  >
                    {{ item.title }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </el-tab-pane>
      <el-tab-pane
        v-else
        label="验货处理"
        name="second"
      >
        <div class="newForm">
          <div class="M_H">
            <el-button
              class="seniorbut"
              @click="YHsub"
            >保 存</el-button>
          </div>
          <div class="M">
            <el-form
              ref="inspectionDetail"
              :model="inspectionDetail"
              :rules="newFormRules"
              label-width="100px"
            >
              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item label="快递单号:">
                    <el-input
                      v-model="inspectionDetail.courierNumber"
                      readonly
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item
                    label="集运仓库:"
                    prop="storageId"
                  >
                    <el-select
                      v-model="inspectionDetail.storageId"
                      clearable
                      placeholder="请选择集运仓库"
                    >
                      <el-option
                        v-for="item in storageArr"
                        :label="item.storageName"
                        :value="item.id"
                        :key="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <!-- <div class="queryItem1">
                  <el-form-item label="验货状态:">
                    <el-select
                      v-model="inspectionDetail.checkStatus"
                      clearable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in YH_Arr"
                        :key="item.value"
                        :label="item.showName"
                        :value="item.statusVal"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div> -->
              </div>
              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item
                    label="申请验货备注:"
                    prop="comment"
                  >
                    <el-input
                      v-model="inspectionDetail.comment"
                      readonly
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item
                    label="处理备注:"
                    prop="inspectionResult"
                  >
                    <el-input
                      v-model="inspectionDetail.inspectionResult"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
          <div class="M">
            <el-table
              border
              :data="inspectionDetail.itemServiceList"
              style="width: 70%"
              size="small"
              :header-cell-style="{ background: '#f0f0f0' }"
            >
              <el-table-column
                label="序号"
                type="index"
                width="50"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="itemServiceTitle"
                align="center"
                label="验货要求"
              ></el-table-column>
              <el-table-column
                align="center"
                label="验货次数"
              >
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.serviceCount"
                    size="mini"
                    style="width: 90%; border: 1px silver solid"
                    placeholder="请输入内容"
                  ></el-input>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="M">
            <el-upload
              class="upload-demo"
              :action="activeUrl"
              list-type="picture-card"
              :limit="limitCount"
              :accept="'image/*'"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :http-request="uploadSectionFile"
              :file-list="fileList"
              :class="{ hide: hideUpload }"
            >
              <i class="el-icon-plus"></i>
              <div
                class="el-upload__tip"
                slot="tip"
              >
                只能上传jpg/png文件，<br />且不超过500kb
              </div>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img
                width="100%"
                :src="dialogImageUrl"
                alt=""
              />
            </el-dialog>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { apiList, Api } from '@/assets/js/api'
import tools from '@/assets/js/tool'

export default {
  data() {
    return {
      //  Organize data
      activeName: 'first', //  tab标识
      pageStatus: '', //  页面标识
      //  新建申请
      loading: false,
      expressNum: '',
      expressNumArr: [],
      isOther: true,
      singleData: {},
      storageArr: [], //  仓库数组数据
      serviceArr: [], //  服务项数据
      expenditureList: [], //费项id列表
      newForm: {
        comment: '',
        packageComment: '',
        storageName: '',
        courierNumber: '',
        itemServices: [],
        storageId: '',
      }, //  新建表单数据
      checkList: [],
      newFormRules: {
        //  新建表单验证配置
        courierNumber: [{ required: true, message: ' ', trigger: 'blur' }],
      },

      //  验收处理数据
      inspectionId: '', //  验货单id
      inspectionDetail: {}, //  编辑详情数据
      YH_Arr: [], //  验货状态数据

      //  上传配置
      limitCount: 10,
      fileList: [],
      activeUrl: '',
      dialogImageUrl: '',
      dialogVisible: false,
      hideUpload: false,
      tltle: '新建验货',
    }
  },
  watch: {
    expenditureList(newvalue, oldvalue) {
      //console.log(newvalue)
      this.newForm.itemServices = []
      newvalue.forEach((item) => {
        var ret4 = this.serviceArr.findIndex((value, index, array) => {
          return item === value.itemServiceId
        })
        ret4 != -1
          ? this.newForm.itemServices.push({
              itemServiceId: this.serviceArr[ret4].itemServiceId,
              itemServiceTitle: this.serviceArr[ret4].title,
              unitPrice: this.serviceArr[ret4].unitPrice,
            })
          : false
        //console.log(this.newForm.itemServices)
      })
    },
  },
  created() {
    this.myGetStorageList() //  获取仓库数组数据
  },
  activated() {
    this.pageStatus = this.$route.query.pageType
    this.inspectionId = this.$route.query.id
    console.log('pageStatus = ' + this.pageStatus)

    //  pageStatus  1 新建验货申请、 2 验收处理 3、修改验货申请

    if (this.pageStatus == '1' || this.pageStatus == '3') {
      this.activeName = 'first'
      this.myGetServiceData() //  获取服务项数组数据
      if (this.pageStatus == '3') {
        this.tltle = '验货修改'
        this.isOther = false
        this.remoteMethod()
        if (this.$route.query.id) {
          this.details(this.$route.query.id)
          this.newForm.storageName = this.$route.query.storageName || ''
          this.newForm.packageComment = this.$route.query.packageComment || ''
        }
      }
    } else if (this.pageStatus == '2') {
      //验收处理
      this.activeName = 'second'
      this.activeUrl = this.common.uploadUrl
      this.myGetInspectionDetail() //  获取验货详情
      this.myGetStatusValList() //  获取验货状态
    }
    console.log(this.activeName)
  },
  mounted() {
    let a = 0
    let b = '0'
  },
  methods: {
    //   Organize event
    myGetStorageList() {
      //  获取仓库数组数据
      Api.getStorageList().then((res) => {
        if (res.data.status === 'success') {
          this.storageArr = res.data.result
        }
      })
    },

    myGetServiceData() {
      //  获取服务项数组数据
      Api.getFx({
        serviceStatus: 'yan_huo:service_status',
      }).then((res) => {
        if (res.data.status === 'success') {
          this.serviceArr = res.data.result || []
        }
      })
    },

    Pc() {
      //  tab切换前回调
      if (this.pageStatus === '1' || this.pageStatus === '3') {
        return false
      }
    },
    //修改获取详情
    details(inspectionId) {
      Api.inspectionId({ inspectionId: inspectionId }).then((res) => {
        //console.log(res.data.result)
        let newForm = res.data.result || []
        // let itemServices = newForm.itemServices || [];
        // itemServices.forEach((item) => {
        //   this.expenditureList.push(item.itemServiceId);
        // });
        this.newForm.comment = newForm.comment || ''
        this.newForm.courierNumber = newForm.courierNumber || ''
        // this.newForm = {
        //   comment: newForm.comment || "",
        //   courierNumber: newForm.courierNumber || "",
        //   // itemServices: [],
        //   // storageId: newForm.storageId || "",
        //   // storageName: newForm.storageName || "",
        //   // packageComment: newForm.packageComment || "",
        // };
      })
    },
    remoteMethod(expressNum) {
      //  select框远程搜索
      this.loading = true
      Api.getExpressNumData({ courierNumber: expressNum }).then((res) => {
        if (res.data.status === 'success') {
          this.loading = false
          this.expressNumArr = res.data.result
        }
      })
    },

    QE() {
      //  查询按钮
      let singleData = this.singleData
      this.newForm.comment = ''
      this.newForm.packageComment = singleData.packageComment || ''
      this.newForm.courierNumber = singleData.courierNumber
      this.newForm.storageId = singleData.storageId || ''
      this.newForm.storageName = singleData.storageName || ''
    },
    SE(val) {
      //  快递单号下拉框选中函数

      this.singleData = val
      this.QE()
    },
    //  新建申请提交
    onSubmit() {
      //  表单提交
      this.$refs['newForm'].validate((valid) => {
        if (valid) {
          if (this.inspectionId) {
            let param = {
              comment: this.newForm.comment || '',
              inspectionId: this.inspectionId,
              courierNumber: this.newForm.courierNumber,
              itemServices: this.newForm.itemServices || [],
              storageId: this.newForm.storageId,
            }
            let sign = tools.getSign(param)
            param.sign = sign
            this.newForm.sign = tools.getSign(param)
            Api.updateInspection(param).then((res) => {
              if (res.data.status === 'success') {
                this.$message.success(res.data.message)
                setTimeout(() => {
                  tools.closePage()
                  this.reload()
                }, 500)
              }
            })
          } else {
            let param = {
              comment: this.newForm.comment || '',
              courierNumber: this.newForm.courierNumber,
              itemServices: this.newForm.itemServices || [],
              storageId: this.newForm.storageId,
            }
            let sign = tools.getSign(param)
            param.sign = sign

            Api.addInspection(param).then((res) => {
              if (res.data.status === 'success') {
                this.$message.success(res.data.message)
                setTimeout(() => {
                  tools.closePage()
                  this.reload()
                }, 500)
              }
            })
          }
        } else {
          return false
        }
      })
    },

    myGetInspectionDetail() {
      //  获取验货详情
      //console.log(this.inspectionId);
      Api.getInspectionDetail({ inspectionId: this.inspectionId }).then(
        (res) => {
          if (res.data.status === 'success') {
            if (this.activeName === 'first') {
              let _list = []
              res.data.result.itemServiceList.forEach((res) => {
                _list.push(res.billItemServiceId)
              })
              this.checkList = _list
              console.log('this.checkList')
              console.log(this.checkList)
              this.newForm = res.data.result
            } else {
              this.inspectionDetail = res.data.result

              let filse = res.data.result.pics || []
              let fileArr = []
              filse.forEach((item) => {
                let fObj = {
                  name: item.fileName,
                  uid: '',
                  url: item.url,
                }
                fileArr.push(fObj)
              })
              this.fileList = fileArr
            }
          }
        }
      )
    },
    myGetStatusValList() {
      //  获取验货状态
      Api.getStatusValList({
        tableAndFieldName: 'bill_inspection_v1.check_status',
      }).then((res) => {
        if (res.data.status === 'success') {
          this.YH_Arr = res.data.result
        }
      })
    },
    // 上传图片
    uploadSectionFile(param) {
      const file = param.file
      const fileName = file.name
      const form = new FormData() // 根据后台需求数据格式
      form.append('file', file) // 文件对象
      form.append('fileName', fileName) // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      let signArr = [
        {
          key: 'fileName',
          val: fileName,
        },
      ]
      let sign = tools.getMD5Sign(signArr)
      form.append('sign', sign)
      Api.uploadFile(form).then((res) => {
        if (res.data.status === 'success') {
          this.fileList.push({
            name: res.data.result.fileName,
            uid: res.data.result.redirectUrl,
            url: res.data.result.url,
          })
        } else {
          this.$message.error(res.data.message)
        }
      })
    },

    //  图片上传事件
    handleRemove(file, fileList) {
      /* 移除图片 */
      this.fileList = fileList
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    YHsub() {
      //  验收要求提交
      this.inspectionDetail.fileNames = []
      this.fileList.forEach((item) => {
        this.inspectionDetail.fileNames.push(item.name)
      })
      //   this.inspectionDetail.sign = tools.getSign(this.inspectionDetail);
      let param = {
        checkStatus: 'dai_fu_kuan:check_status',
        // checkStatus: this.inspectionDetail.checkStatus || 'dai_fu_kuan:check_status',
        fileNames: this.inspectionDetail.fileNames || [],
        inspectionId: this.inspectionDetail.inspectionId,
        inspectionResult: this.inspectionDetail.inspectionResult || '',
        itemService: this.inspectionDetail.itemServiceList || [],
      }
      let sign = tools.getSign(param)
      param.sign = sign
      Api.dealInspection(param).then((res) => {
        if (res.data.status === 'success') {
          this.$message.success(res.data.message)
          setTimeout(() => {
            this.$router.push({
              path: '/BusinessManagement/Warehousing/InspectionManagement',
            })
          }, 1000)
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.M {
  margin-bottom: 30px;
}

.hide .el-upload--picture-card {
  display: none;
}

.alonebut {
  margin-left: 10px;
  height: 28px;
}
</style>
